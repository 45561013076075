<template>
	<div class="app-view">
		<div class="controls-container settings-container">
      <div class="settings-container-headline">Bundesland:</div>
      <div class="select-wrapper">
        <select v-model="selectedBundesland" v-if="dirData">
          <option v-for="blDir in dirData" :key="blDir.name" :value="blDir.bez">{{ blDir.name }}</option>
        </select>
        <select v-else></select>
      </div>

      <div class="settings-container-headline">Ergebnisse aus dem Jahr:</div>
      <div class="select-wrapper">
        <select v-model="selectedYear" v-if="filteredDirData">
          <option v-for="year in filteredDirData.dir" :key="year.bez" :value="year.name">{{ year.bez }}</option>
        </select>
        <select v-else></select>
      </div>
		</div>

    <div class="filelist-outer-wrapper" v-if="filteredDirData">
      <Filelist :filedir="fileListDir" :bundesland="selectedBundesland" :jahr="selectedYear"></Filelist>
    </div>

    <div class="filelist-outer-wrapper" v-if="0">
      <div class="filelist-art-wrapper">
        <div class="filelist-art">
          <div class="img-wrapper"><img src="/img/icons/check_koernermais.svg" ></div> <div>Koernermais</div>
        </div>
        <div class="filelist-files">
          <div class="filelist-file">
            <div class="img-wrapper"><img src="@/assets/icons/pdf-icon.svg" ></div> <div><a href="">Blablablabla 1.pdf</a></div>
          </div>
        </div>
      </div>
      <div class="filelist-art-wrapper">
        <div class="filelist-art">
          <div class="img-wrapper"><img src="/img/icons/check_silomais.svg" ></div> <div>Silomais</div>
        </div>
        <div class="filelist-files">
          <div class="filelist-file">
            <div class="img-wrapper"><img src="@/assets/icons/pdf-icon.svg" ></div> <div><a href="">Blablablabla 2.pdf</a></div>
          </div>
        </div>
        <div class="filelist-subart-wrapper active">
          <div class="filelist-subart-title" @click="toggleSubart($event)">
            frueh
          </div>
          <div class="filelist-subart">
            <div class="filelist-subart-inner">
              <div class="filelist-subart-file">
                <div class="img-wrapper"><img src="@/assets/icons/pdf-icon.svg" ></div> <div><a href="">Blablablabla 3.pdf</a></div>
              </div>
              <div class="filelist-subart-file">
                <div class="img-wrapper"><img src="@/assets/icons/pdf-icon.svg" ></div> <div><a href="">Blablablabla 4.pdf</a></div>
              </div>
              <div class="filelist-subart-file">
                <div class="img-wrapper"><img src="@/assets/icons/pdf-icon.svg" ></div> <div><a href="">Blablablabla 5.pdf</a></div>
              </div>
            </div>
          </div>
        </div>
        <div class="filelist-subart-wrapper">
          <div class="filelist-subart-title" @click="toggleSubart($event)">
            mittelfrueh
          </div>
          <div class="filelist-subart" style="height: 0px;">
            <div class="filelist-subart-inner">
              <div class="filelist-subart-file">
                <div class="img-wrapper"><img src="@/assets/icons/pdf-icon.svg" ></div> <div><a href="">Blablablabla 6.pdf</a></div>
              </div>
              <div class="filelist-subart-file">
                <div class="img-wrapper"><img src="@/assets/icons/pdf-icon.svg" ></div> <div><a href="">Blablablabla 7.pdf</a></div>
              </div>
              <div class="filelist-subart-file">
                <div class="img-wrapper"><img src="@/assets/icons/pdf-icon.svg" ></div> <div><a href="">Blablablabla 8.pdf</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

		<div v-if="0" class="results-outer-wrapper" v-show="filteredCornData.length == 0 && calcCorn == null && !activeForm">
      <div class="results-compared-props">
        <div>Sorte ({{ maissortenSubset.length }})</div>
        <div>Nutzung</div>
        <div>Reifezahl</div>
      </div>
      <div class="results-inner-wrapper">
        <div class="single-result" :class="{ activeCompare: compareList !== null && compareList.includes(sorte.corn_id)}" v-for="sorte of maissortenSubset" :key="sorte.Sorte" @click="enableCompare ? toggleCompareItem($event, sorte.corn_id) : toggleActive($event)">
          <div class="result-compare-property">
            <div class="result-properties">
              <div class="result-property">
                <div class="property-key">Preis pro Einheit*</div>
                <div class="property-value"><div>{{ sorte.Preis }}</div></div>
              </div>
              <div class="result-property">
                <div class="property-key">Beize Standard</div>
                <div class="property-value"><button @click="bestellAnfrage(sorte.Sorte + ' (Beize Standard)')">Bestellanfrage</button></div>
              </div>
              <div class="result-property">
                <div class="property-key">Beize Maisguard<sup>&reg;</sup></div>
                <div class="property-value"><button v-if="sorte.noMAISGUARD == '0'" @click="bestellAnfrage(sorte.Sorte + ' (Beize Maisguard)')">Bestellanfrage</button></div>
              </div>

              <div class="result-property">
                <div class="property-key">Gesamttrockenmasse</div>
                <div class="property-value">
                  <div v-if="sorte.Gesamttrockenmasse !== null && sorte.Gesamttrockenmasse !== '0'">
                    <div v-for="index in parseInt(sorte.Gesamttrockenmasse)" :key="index">&#x25CF;</div>
                  </div>
                  <div v-else>-</div>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">Energieertrag</div>
                <div class="property-value">
                  <div v-if="sorte.Energieertrag_nicht_BSA !== null && sorte.Energieertrag_nicht_BSA !== '0'">
                    <div v-for="index in parseInt(sorte.Energieertrag_nicht_BSA)" :key="index">&#x25CF;</div>
                  </div>
                  <div v-else>-</div>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">Stärkeertrag</div>
                <div class="property-value">
                  <div v-if="sorte.Staerkeertrag_nicht_BSA !== null && sorte.Staerkeertrag_nicht_BSA !== '0'">
                    <div v-for="index in parseInt(sorte.Staerkeertrag_nicht_BSA)" :key="index">&#x25CF;</div>
                  </div>
                  <div v-else>-</div>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">Verdaulichkeit</div>
                <div class="property-value">
                  <div v-if="sorte.Verdaulichkeit !== null && sorte.Verdaulichkeit !== '0'">
                    <div v-for="index in parseInt(sorte.Verdaulichkeit)" :key="index">&#x25CF;</div>
                  </div>
                  <div v-else>-</div>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">Kornertrag</div>
                <div class="property-value">
                  <div v-if="sorte.Kornertrag !== null && sorte.Kornertrag !== '0'">
                    <div v-for="index in parseInt(sorte.Kornertrag)" :key="index">&#x25CF;</div>
                  </div>
                  <div v-else>-</div>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">Pflanzenlänge</div>
                <div class="property-value">
                  <div v-if="sorte.Pflanzenlaenge !== null && sorte.Pflanzenlaenge !== '0'">
                    <div v-for="index in parseInt(sorte.Pflanzenlaenge)" :key="index">&#x25CF;</div>
                  </div>
                  <div v-else>-</div>
                </div>
              </div>

              <div class="result-headline">Eigenschaften</div>

              <div class="result-property">
                <div class="property-key">Jugendentwicklung</div>
                <div class="property-value">
                  <div v-if="sorte.Jugendentwicklung !== null && sorte.Jugendentwicklung !== ''" v-html="sorte.Anfaelligk_f_Staengelfaeule"></div>
                  <div v-else>-</div>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">Anfälligk. für Stängelfäule</div>
                <div class="property-value">
                  <div v-if="sorte.Anfaelligk_f_Staengelfaeule !== null && sorte.Anfaelligk_f_Staengelfaeule !== ''" v-html="sorte.Anfaelligk_f_Staengelfaeule"></div>
                  <div v-else>-</div>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">Standfestigkeit</div>
                <div class="property-value">
                  <div v-if="sorte.Standfestigkeit !== null && sorte.Standfestigkeit !== ''" v-html="sorte.Standfestigkeit"></div>
                  <div v-else>-</div>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">Neigung zu Bestockung</div>
                <div class="property-value">
                  <div v-if="sorte.Neigung_zu_Bestockung !== null && sorte.Neigung_zu_Bestockung !== ''" v-html="sorte.Neigung_zu_Bestockung"></div>
                  <div v-else>-</div>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">Stay Green</div>
                <div class="property-value">
                  <div v-if="sorte.Stay_Green !== null && sorte.Stay_Green !== ''" v-html="sorte.Stay_Green"></div>
                  <div v-else>-</div>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">Anfälligk. für Stängelfäule</div>
                <div class="property-value">
                  <div v-if="sorte.Anfaelligk_f_Staengelfaeule !== null && sorte.Anfaelligk_f_Staengelfaeule !== ''" v-html="sorte.Anfaelligk_f_Staengelfaeule"></div>
                  <div v-else>-</div>
                </div>
              </div>

              <div class="result-headline">Bestandesdichte in Pflanzen/m<sup>2</sup></div>

              <div class="result-property">
                <div class="property-key main">Silomais</div>
              </div>
              <div class="result-property">
                <div class="property-key">gute Standorte:</div>
                <div class="property-value">
                  <div v-if="sorte.Bestandesdichte_Sg !== null && sorte.Bestandesdichte_Sg !== ''" v-html="sorte.Bestandesdichte_Sg"></div>
                  <div v-else>-</div>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">trockene Standorte:</div>
                <div class="property-value">
                  <div v-if="sorte.Bestandesdichte_St !== null && sorte.Bestandesdichte_St !== ''" v-html="sorte.Bestandesdichte_St"></div>
                  <div v-else>-</div>
                </div>
              </div>

              <div class="result-property">
                <div class="property-key main">Körnermais</div>
              </div>
              <div class="result-property">
                <div class="property-key">gute Standorte:</div>
                <div class="property-value">
                  <div v-if="sorte.Bestandesdichte_Kg !== null && sorte.Bestandesdichte_Kg !== ''" v-html="sorte.Bestandesdichte_Kg"></div>
                  <div v-else>-</div>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">trockene Standorte:</div>
                <div class="property-value">
                  <div v-if="sorte.Bestandesdichte_Kt !== null && sorte.Bestandesdichte_Kt !== ''" v-html="sorte.Bestandesdichte_Kt"></div>
                  <div v-else>-</div>
                </div>
              </div>
              <div class="result-property">
                <div class="property-key">Sortenblatt:</div>
                <div class="property-value">
                  <div><a :href="'https://maisscout.de/res/sorts/' + sorte.Sorte.trim() + '.pdf'" target="_blank"><img src="../assets/icons/pdf-icon.svg"></a></div>
                </div>
              </div>


            </div>
            <div class="property-value"><div>{{sorte.Sorte}}<div class="sold-out" v-if="sorte.SorteSub">-ausverkauft-</div></div><img :src="sorte.Logo" /></div>
          </div>
          <div class="result-compare-property">
            <img src="/img/icons/check_silomais.svg" v-if="sorte.Silomais == '1'" id="silopic">
            <img src="/img/icons/check_koernermais.svg" v-if="sorte.Koernermais == '1'" id="kornpic">
            <img src="/img/icons/check_biogasmais.svg" v-if="sorte.Biogasmais == '1'" id="biogaspic">
          </div>
          <div class="result-compare-property">
            <div v-if="sorte.Siloreifezahl_circa">{{sorte.Siloreifezahl_circa}} <div class="green">S</div></div>
            <div v-else-if="sorte.Siloreifezahl">{{sorte.Siloreifezahl}}</div>
            <div v-if="sorte.Koernerreifezahl_circa">{{sorte.Koernerreifezahl_circa}} <div class="green">K</div></div>
            <div v-else-if="sorte.Koernerreifezahl">{{sorte.Koernerreifezahl}}</div>
          </div>
        </div>
      </div>
		</div>

	</div>
</template>


<style lang="scss">

body { margin: 0; }
// .app-view { max-width: 414px; margin: 0 auto; }

// .maisscout {
//   background-color: #EEEEEE;
//   .app-inner {
//     display: flex; flex-direction: column;
//     height: 100vh;
//   }
// }

.controls-container {
  background-color: #EEEFEE;
  padding: 20px; padding-bottom: 0;
}

.settings-container {
  .settings-container-headline {
    color: #B5B5B5;
    font-size: 16px; font-weight: 700; line-height: 22px;
    text-align: left; text-transform: uppercase;
  }
}

.select-wrapper {
  background: linear-gradient(to bottom, #FFFFFF, #EAEAEA);
  height: 40px;
  margin-bottom: 9px;
  position: relative;
  &::after {
    border-top: 3px solid #C3C3C3; border-right: 3px solid #C3C3C3; border-bottom: 0; border-left: 0;
    content: '';
    display: inline-block;
    padding-top: 6px; padding-right: 6px;
    position: absolute; top: 15px; right: 15px;
    transform: rotate(135deg);
  }
  select {
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid #C3C3C3; border-radius: 3px;
    background: transparent;
    font-size: 16px;
    position: absolute; top: 0; left: 0;
    width: 100%; height: 100%;
    padding: 9px 12px;
    &:focus { outline: none; }
  }
}

.filelist-outer-wrapper {
  background-color: #ffffff;
  height: 100%;
  .filelist-art-wrapper {
    .filelist-art {
      border-bottom: 1px solid #B5B5B5;
      display: flex; align-items: center;
      padding: 10px 20px;
      .img-wrapper {
        flex: 0 0 50px;
        margin-right: 10px;
        img {
          height: 30px; width: auto;
        }
      }
      div {
        color: #B5B5B5;
        font-size: 16px; font-weight: 700; line-height: 22px;
        text-transform: uppercase;
      }
    }
    .filelist-files {
      // margin-bottom: 12px;
      .filelist-file {
        display: flex; align-items: center;
        padding: 12px 20px;
        .img-wrapper {
          flex: 0 0 50px;
          margin-right: 10px;
          img {
            height: 20px; width: auto;
          }
        }
        div:not(.img-wrapper) {
          text-align: left;
          a {
            color: #58645A;
            font-size: 11px; line-height: 16px;
            text-decoration: none;
          }
        }
      }
    }
    .filelist-subart-wrapper {
      .filelist-subart-title {
        background-color: #EEEEEE;
        color: #007858;
        cursor: pointer;
        padding: 9px;
        position: relative;
        user-select: none;
        border-bottom: 1px solid #B5B5B5;
        &::before {
          content: none;
          position: absolute;
          top: 100%;
          left: 0;
          border-color: transparent #003a2d transparent transparent;
          border-style: solid;
          border-width: 0 9px 9px 0;
          width: 0;
          height: 0;
          z-index: 10;
        }
        &::after {
          background-color: transparent;
          border-top: 3px solid #007858;
          border-right: 3px solid #007858;
          border-bottom: 0;
          border-left: 0;
          padding-top: 8px;
          padding-right: 8px;
          top: 0;
          width: auto;
          height: auto;
          content: '';
          display: block;
          position: absolute; top: 50%; right: 10px;
          transform: translateY(-66%) rotate(135deg);
          transition: all .4s ease-out;
        }

      }
      .filelist-subart {
        // height: 0;
        margin-left: 9px;
        overflow: hidden;
        transition: height .3s ease-in-out;
        .filelist-subart-inner {
          background-color: #E8F6F5;
          padding: 4px 20px;
          .filelist-subart-file {
            display: flex; align-items: center;
            margin-bottom: 10px;
            .img-wrapper {
              flex: 0 0 31px;
              margin-right: 20px;
              img {
                height: 20px; width: auto;
              }
            }
            div:not(.img-wrapper) {
              text-align: left;
              a {
                color: #58645A;
                font-size: 11px; line-height: 16px;
                text-decoration: none;
              }
            }
          }
          .filelist-subart-file-outer {
            .filelist-subart-subtitle {
              color: #007858;
              font-size: 11px; font-weight: 700; line-height: 16px;
              text-align: left;
              & + div {
                margin-top: 8px;
              }
            }
          }
        }
      }
      // &.closed {
      //   .filelist-subart-title {
      //     background-color: #EEEEEE;
      //     color: #007858;
      //     font-size: 18px; line-height: 22px;
      //     &::before { 
      //       content: none;
      //       }
      //     &::after {
      //       border-color: #007858;
      //       transform: translateY(-66%) rotate(135deg);
      //     }
      //   }
      //   .filelist-subart { display: none; }
      // }
      &.active {
        .filelist-subart-title {
          background-color: #007858;
          border-color: #007858;
          color: #FFFFFF;
          // font-size: 18px; line-height: 22px;
          &::before { 
            content: '';
            }
          &::after {
            border-color: #FFFFFF;
            transform: translateY(-40%) rotate(-45deg);
          }
        }
        .filelist-subart { height: auto; }
      }
    }
  }
}



// @media screen and(min-width: 769px) {
//   .filter-outer-wrapper {
//     .filter-wrapper {
//       .single-filter:not(.filter-range) {
//         flex: 0 0 50%
//       }
//     }
//   }
// }

@media screen and(min-width: 961px) {
  .filter-outer-wrapper {
    .filter-wrapper {
      .single-filter {
        flex: 0 0 calc(100% / 3);
        &.filter-range, &.filter-select {
          flex: 0 0 50%;
        }
      }
    }
  }
}

* { box-sizing: border-box; }
button { touch-action: manipulation; }

</style>

<script>
import axios from 'axios'
import lodash from 'lodash'
import Filelist from '@/components/Filelist.vue'

export default {
    name: 'Maisscout',
    components: {
      Filelist
    },
		data(){
			return {
        fileListLoaded: false,
        selectedBundesland: '',
        selectedYear: '',
        dirData: []
        //weiter machen
			}
		},
    computed: {
      filteredDirData: {
        get(){
          if(this.fileListLoaded) { 
            // console.log("jo")
            return this.dirData.find(dir => { if(dir.name == this.selectedBundesland && dir.dir.find(subdir => { if(subdir.name == this.selectedYear) return subdir })) return dir })
          }
          return false
        }
      },
      fileListDir: {
        get(){
          if(this.fileListLoaded) {
            return (typeof this.filteredDirData !== 'undefined') ? this.filteredDirData.dir.find(yearDir => { if(yearDir.name == this.selectedYear) return yearDir }) : undefined
          }
          return false
        }
      }
    },
		methods: {
		},
    watch: {
      
      selectedBundesland(newValue){
        const blDir = this.dirData.find(dir => { if(dir.name == this.selectedBundesland) return dir })
        if(typeof blDir !== 'undefined') this.selectedYear = blDir.dir[0].name
        // this.$forceUpdate()
        // this.selectedYear = ''
      }
    },
		created() {

      axios.get('https://stroetmann-saat.de/?tx_stroetmannext_zipsearch[controller]=LsvMais').then(response => {
        // console.log(response.data)
        if(response.data) this.dirData = response.data.filter(function f(o) {
          if (o.file) return true

          if (o.dir) {
            return (o.dir = o.dir.filter(f)).length
          }
        })
        // if(response.data) this.dirData = response.data.filter(blDir => {
        //   if(blDir.dir.length > 0) return blDir
        // })
        this.selectedBundesland = this.dirData[0].name
        this.selectedYear = this.dirData[0].dir[0].name
        this.fileListLoaded = true
        // console.log("jo2")
      })
      .catch(e => {
        console.log(e)
      })
		}
}
</script>