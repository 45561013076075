<template>

    <div class="filelist-inner-wrapper" v-if="filedir">
      <div class="filelist-art-wrapper" v-for="generalFile in subdir.dir" :key="generalFile.file">
        <div class="filelist-files">
          <div class="filelist-file-outer">
            <div class="filelist-file" v-if="generalFile.file">
              <div class="img-wrapper"><img src="@/assets/icons/pdf-icon.svg" ></div> <div><a target="_blank" :href="`https://stroetmann-saat.de/lsv_mais/${bundesland}/${jahr}/${generalFile.file}`">{{ generalFile.file }}</a></div>
            </div>
          </div>
        </div>
      </div>
      <div class="filelist-art-wrapper" v-for="artenDir in subdir.dir" :key="artenDir.bez">
        <div class="filelist-art" v-if="artenDir.bez">
          <div class="img-wrapper"><img :src="`/img/icons/check_${getLowerCase(artenDir.bez)}.svg`" ></div> <div>{{ artenDir.bez }}</div>
        </div>
        <div class="filelist-files" v-if="artenDir.dir && artenDir.dir.length">
          <div class="filelist-file-outer" v-for="artenFile in artenDir.dir" :key="artenFile.file">
            <div class="filelist-file" v-if="artenFile.file">
              <div class="img-wrapper"><img src="@/assets/icons/pdf-icon.svg" ></div> <div><a target="_blank" :href="`https://stroetmann-saat.de/lsv_mais/${bundesland}/${jahr}/${artenDir.name}/${artenFile.file}`">{{ artenFile.file }}</a></div>
            </div>
          </div>

        </div>
        <div class="filelist-subarts-wrapper" v-if="artenDir.dir && artenDir.dir.length">
          <div class="filelist-subart-outer-wrapper" v-for="artenFile in artenDir.dir" :key="artenFile.bez">
            <div class="filelist-subart-wrapper" v-if="artenFile.bez">
              <div class="filelist-subart-title" @click="toggleSubart($event)">{{ artenFile.bez }}</div>
              <div class="filelist-subart" style="height: 0px;" v-if="artenFile.dir.length">
                <div class="filelist-subart-inner">
                  <div class="filelist-subart-file-outer" v-for="subartenFile in artenFile.dir" :key="subartenFile.file">
                    <div class="filelist-subart-file" v-if="subartenFile.file">
                      <div class="img-wrapper"><img src="@/assets/icons/pdf-icon.svg" ></div> <div><a target="_blank" :href="`https://stroetmann-saat.de/lsv_mais/${bundesland}/${jahr}/${artenDir.name}/${artenFile.name}/${subartenFile.file}`">{{ subartenFile.file }}</a></div>
                    </div>
                  </div>
                  <div v-if="artenFile.dir && artenFile.dir.length">
                    <div class="filelist-subart-file-outer" v-for="subartenSubdir in artenFile.dir" :key="subartenSubdir.bez">
                      <div class="filelist-subart-subtitle">{{ subartenSubdir.bez }}</div>
                      <div v-for="subartenSubfile in subartenSubdir.dir" :key="subartenSubfile.file">
                        <div class="filelist-subart-file" v-if="subartenSubfile.file">
                          <div class="img-wrapper"><img src="@/assets/icons/pdf-icon.svg" ></div> <div><a target="_blank" :href="`https://stroetmann-saat.de/lsv_mais/${bundesland}/${jahr}/${artenDir.name}/${artenFile.name}/${subartenSubdir.name}/${subartenSubfile.file}`">{{ subartenSubfile.file }}</a></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="filelist-wrapper" v-else>

    </div>



</template>

<style lang="scss">

</style>

<script>
// import FilelistChild from '@/components/FilelistChild.vue'

export default {
  name: "Filelist",
  props: {
    filedir: Object,
    bundesland: String,
    jahr: String
  },
  // components: {
  //   FilelistChild
  // },
  methods: {
      getLowerCase(str) {
        return (typeof str !== 'undefined') ? str.toLowerCase() : ''
      },
      toggleSubart(event){
        const active = event.target.parentNode.classList.contains('active')
        document.querySelectorAll('.filelist-subart-title').forEach(subfileWrapper => {
          subfileWrapper.parentNode.classList.remove('active')
          subfileWrapper.nextSibling.style.height = "0px"
        })
        if(!active) {
          event.target.parentNode.classList.add('active')
          event.target.nextSibling.style.height = event.target.nextSibling.scrollHeight + "px"
        }
      }
  },
  watch: {
    subdir: {
      handler: function(){
        window.setTimeout(() => {
          document.querySelectorAll('.filelist-subart-title').forEach(subfileWrapper => {
            subfileWrapper.parentNode.classList.remove('active')
            subfileWrapper.nextSibling.style.height = "0px"
          })
          const firstSubartTitle = document.querySelector('.filelist-subart-title')
          if(firstSubartTitle) {
            
            firstSubartTitle.parentNode.classList.add('active')
            firstSubartTitle.nextSibling.style.height = firstSubartTitle.nextSibling.scrollHeight + "px"
          }
        }, 10)
      },
    }
  },
  computed: {
    subdir: {
      get(){
        // console.log("get()")
        return this.filedir
      }
    }
  },
  mounted(){
    window.setTimeout(() => {
      document.querySelectorAll('.filelist-subart-title').forEach(subfileWrapper => {
        subfileWrapper.parentNode.classList.remove('active')
        subfileWrapper.nextSibling.style.height = "0px"
      })
      const firstSubartTitle = document.querySelector('.filelist-subart-title')
      if(firstSubartTitle){
        firstSubartTitle.parentNode.classList.add('active')
        firstSubartTitle.nextSibling.style.height = firstSubartTitle.nextSibling.scrollHeight + "px"
      }
    }, 10)
  }
};
</script>